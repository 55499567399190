import request from '@/utils/request'

export const apiUpload = (data: any) =>
  request({
    url: '/v2/works ',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data
})

export const apiList = (params: any) =>
  request({
    url: '/v2/works',
    method: 'get',
    params
})

export const apiDelete = (data: any) =>
  request({
    url: `/v2/works/${data.id}`,
    method: 'delete'
})

export const apiGetMusic = (data: any) =>
  request({
    url: `/v2/works/${data.id}`,
    method: 'get'
})

export const apiUpdateMusic = (data: any) =>
  request({
    url: `/v2/works/update`,
    method: 'post',
    data
})

export const apiUploadCover = (data: any) =>
  request({
    url: `/v2/upload/cover`,
    headers: { "Content-Type": "application/form-data" },
    method: 'post',
    data
})

export const apiUploadImg = (data: any) =>
  request({
    url: `/v2/upload/image`,
    headers: { "Content-Type": "multipart/form-data" },
    method: 'post',
    data
})

export const uporpublish = (data: any) =>
  request({
    url: `v2/ishot`,
    method: 'post',
    data
})