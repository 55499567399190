











































































































































































let isUpload = false;
let uploadType = 0;  // 通过哪种类型上传调用的裁剪器，0是封面图，1是歌曲图片
import { Component, Vue } from 'vue-property-decorator'
import { getToken } from '@/utils/cookies'
import { apiUpload, apiUploadCover, apiUploadImg } from '@/api/music'
import { apiGetUserInfo } from '@/api/profile'
import { apiGetMusic, apiUpdateMusic } from '@/api/music'
import { getDuration, getImgSize, file2Base64 } from '@/utils/index'
import { getWordsData } from '@/utils/words'
import Bind from '@/components/Dialog/bind.vue'
import OhMyCut from '@/components/common/cut.vue'
@Component({
  name: 'Form',
  components: {
    Bind,
    OhMyCut
  }
})

export default class extends Vue {
  private dialogVisible2 = false
  private otherWayToClose = false
  private form = {
    title: '',
    cover: '',
    source_url: '',
    lyric: '',
    introduction: '',
    quick_start: '',
    quick_end: '',
    share_avatar: '',
    imgs: [],
    style: [],
    // category: '',
    duration: 0
  };
  private cutDialog = false
  private curBase64 = ''
  private checked = true;
  private typeOptions = [
    {
      label: '录音棚',
      value: '录音棚'
    },
    {
      label: '现场版',
      value: '现场版'
    },
    {
      label: 'Demo',
      value: 'Demo'
    }
  ]
  private options = [
    {
      label:'民谣',
      value:'民谣'
    },
    {
      label:'摇滚',
      value:'摇滚'
    },
    {
      label:'说唱',
      value:'说唱'
    },
    {
      label:'流行',
      value:'流行'
    },
    {
      label:'电音',
      value:'电音'
    },
    {
      label:'古典',
      value:'古典'
    },
    {
      label:'乡村',
      value:'乡村'
    },
    {
      label:'古风',
      value:'古风'
    },
    {
      label:'其他',
      value:'其他'
    }
  ]
  private dialogVisible = false;
  private contentImgArr: [] = [];
  private hideUploadEdit: boolean = false;
  private isEdit: boolean = false;
  private musicUploadLoading: boolean = false;
  private tempCoverImg = '';
  private musicUploadLimit: number = 1;
  private showMusicFileList = true;
  private showCoverFileList = false
  private musicFileObj = {
    name:'',
    url:'',
    size:''
  }
  private rules = {
    title: [
      { required: true, message: '请输入歌曲名称', trigger: 'blur' },
      { min:1, max: 50, message: '歌曲名字数限制1-50字符', trigger: 'blur' },
    ],
    cover: [
      { required: true, message: '请上传歌曲封面图', trigger: 'blur' },
    ],
    source_url: [
      { required: true, message: '请上传歌曲', trigger: 'blur' },
    ],
    quick_start:[
      { required: true, message: '请输入精选片段开始时间', trigger: 'blur' }
    ],
    quick_end:[
      { required: true, message: '请输入精选片段结束时间', trigger: 'blur' }
    ],
    lyric: [
      // { required: false,  message: '歌词字数限制10-2000字', trigger: 'blur' }
    ],
    introduction: [
      { required: false, min: 10, max: 200, message: '歌曲介绍字数限制10-200字', trigger: 'blur' }
    ],
    category: [
      { required: true,  message: '请选择音乐分类', trigger: 'blur' }
    ],
    style: [
      { required: true,  message: '请选择歌曲风格', trigger: 'blur' }
    ]
  }
  private done = false

  private uploadHeaders = {Authorization: `Bearer ${getToken()}`}
  private imgAction = `${process.env.VUE_APP_BASE_API}/v2/upload/image`
  private coverAction = `${process.env.VUE_APP_BASE_API}/v2/upload/cover`
  private audioAction = `${process.env.VUE_APP_BASE_API}/v2/upload/file`

  private onSubmit(): void {
    if(!this.checked) {
      this.$message({
        message: '请知晓授权书内容后继续',
        type: 'warning'
      })
    }else{
      if(this.form.style.length > 3){
        this.$message({
          message: '音乐风格最多可以选择三种',
          type: 'warning'
        })
      }else{
        this.dialogSubmit();
      }
    }
  }

  private onCancel(): void {
    this.$message({
      message: 'cancel!',
      type: 'warning'
    })
  }
  // 封面图上传成功回调
  private coverHandleSuccess(res: any, file: any): void {
    this.form.cover = res.data.cover;
    this.tempCoverImg = res.data.cover;
    this.form.share_avatar = res.data.share_avatar;
    this.messageSuc();
  }
  private handleRemove(file: any, fileList: any): void {
    console.log(this.form.imgs)
    this.form.imgs = this.form.imgs.filter((i: string)=> {
      return file.url !== i;
    })
    if(fileList.length < 3){
      this.hideUploadEdit = false;
    }
    (this.contentImgArr as any) = this.contentImgArr.filter((i: {url: string, name: string}) => {
      return file.url !== i.url;
    })
    console.log(this.contentImgArr)
  }
  private dialogSubmit() {
    this.dialogVisible = false;
    (this.$refs['form'] as any).validate((valid: any) => {
      if(valid) {
        getDuration(this.form.source_url).then((res)=> {
            if(this.form.quick_start > (res as any) || this.form.quick_end > (res as any)){
              this.$message({
                message: '快听开始或结束时间不得大于歌曲总时长',
                type: 'warning'
              })
            }else if(this.form.quick_start >= this.form.quick_end) {
              this.$message({
                message: '快听开始时间必须小于快听结束时间',
                type: 'warning'
              })
            }else if(getWordsData().indexOf(this.form.title) !== -1) {
              this.$message({
                message: '标题包含违禁词汇，请修改后重试',
                type: 'warning'
              })
            }else{
              if(this.form.duration) {
                if(this.isEdit){
                  apiUpdateMusic({
                    ...this.form,
                    style: Array.isArray(this.form.style) ? this.form.style.join(',') : "",
                    imgs: this.form.imgs.join(';')
                  }).then((res:any)=> {
                    this.$message({
                      message: '操作成功',
                      type: 'success'
                    })
                    this.$router.go(-1);
                    })
                  }else{
                    apiUpload({
                      ...this.form,
                      style: Array.isArray(this.form.style) ? this.form.style.join(',') : "",
                      imgs: this.form.imgs.join(';')
                    }).then((res)=> {
                      this.done = true;
                      window.sessionStorage.removeItem('musician_form_temp')
                      this.$message({
                        message: '操作成功',
                        type: 'success'
                      })
                      this.$router.go(-1);
                    })
                  }
              }else{
                this.$message({
                  message: '请等待上传的歌曲加载到可获取总时长之后保存',
                  type: 'warning'
                });
              }
              
            }
          })
      }else{
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }

  created() {
    if(this.$route.query.id){
      this.findMusic();
      this.isEdit = true;
    }else{
      if(window.sessionStorage.getItem('musician_form_temp')) {
        let data = JSON.parse((window.sessionStorage as any).getItem('musician_form_temp'));
        this.form = data;
        this.tempCoverImg = data.cover;
        
        if(typeof data.imgs === "string"){
          data.imgs = data.imgs.split(';')
        }
        this.contentImgArr = data.imgs.map((item: any)=>{
          return {
            name:'img1.jpg',
            url: `${item}`
          }
        })
        // this.form.imgs = this.form.imgs
        if(this.contentImgArr.length >= 3){
          this.hideUploadEdit = true;
        }
        // 音乐回显
        this.musicFileObj.name = data.title ? `${data.title}.mp3` : data.title;
        this.musicFileObj.size = '-';
        this.musicFileObj.url = data.source_url ? `${data.source_url}` : data.title;
      }
    }
    this.getInfoData();
  }

  mounted() {
    let t = this.$refs['audio'];
    console.log(t)
    let self = this;
    (t as any).addEventListener('canplaythrough',function() {
      self.form.duration = Number(parseInt((t as any).duration));
      console.log(self.form.duration)
    })
  }

  beforeDestroy() {
    if(!this.isEdit && !this.done) {
      window.sessionStorage.setItem('musician_form_temp', JSON.stringify(this.form))
    }
  }

  private handleEditChange(file: any, fileList: any): void{
    this.hideUploadEdit = fileList.length >= 3
  }

  private messageSuc(): void {
    this.$message({
      message: '上传成功',
      type: 'success'
    });
  }
  // 上传音乐成功回调
  private musicHandleSuccess(res: any, file: any): void {
    this.form.source_url = res.data.filename;
    this.musicFileObj.url = res.data.filename;
    this.musicFileObj.name = res.origin;
    this.musicFileObj.size = res.size;
    this.musicUploadLoading = false;
    this.messageSuc();
    isUpload = true;
  }
  private musicBeforeUpload(file: any) {
    this.musicUploadLoading = true;
    const fileSuffix = file.type
    const whiteList = ["audio/wav", "audio/mpeg"];
    if (whiteList.indexOf(fileSuffix) === -1) {
      this.$message.error('音乐文件只支持mp3、wav格式');
      return false;
    }

    const isLt20M = file.size / 1024 / 1024 < 20;
    if(!isLt20M){
      this.musicUploadLoading = false;
      this.$message({
        message: '文件大小不得超过20MB',
        type: 'warning'
      })
    }
    return isLt20M;
  }
  private coverBeforeUpload(file: any) {
    console.log('cover before')
    uploadType = 0;
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
        this.$message({
          message: '文件大小不得超过10MB',
          type: 'warning'
        })
    }
    const isSize = new Promise(function(resolve: any, reject) {
      let width = 300;
      let height = 300;
      let _URL = window.URL || window.webkitURL;
      let image = new Image();
      image.onload = function() {
        let valid = image.width == width && image.height == height;
        valid ? resolve() : reject();
      };
      image.src = _URL.createObjectURL(file);
    }).then(
      () => {
        return file;
      },
      () => {
        // this.$message({
        //   message: '图片尺寸只能是300*300',
        //   type: 'warning'
        // })
        this.cutDialog = true;
        let _URL = window.URL || window.webkitURL;
        this.curBase64 = _URL.createObjectURL(file)
        return Promise.reject();
      }
    );
    return isLt10M && isSize;
  }

  private imgBeforeUpload(file: any) {
    console.log('img before')
    uploadType = 1;
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
        this.$message({
          message: '文件大小不得超过10MB',
          type: 'warning'
        })
    }
    const isSize = new Promise(function(resolve: any, reject) {
      let width = 300;
      let height = 300;
      let _URL = window.URL || window.webkitURL;
      let image = new Image();
      image.onload = function() {
        let valid = image.width == width && image.height == height;
        valid ? resolve() : reject();
      };
      image.src = _URL.createObjectURL(file);
    }).then(
      () => {
        return file;
      },
      () => {
        this.cutDialog = true;
        let _URL = window.URL || window.webkitURL;
        this.curBase64 = _URL.createObjectURL(file)
        return Promise.reject();
      }
    );
    return isLt10M && isSize;
  }

  private musicHandleExceed(file: any): void {
    this.$message.error('最多只能上传一个文件');
  }

  private contentImgHandleSuccess(res: any, file: any): void{
    (this.form.imgs as any).push(res.data.filename)
  }

  private getInfoData():void {
    apiGetUserInfo().then((res: any)=> {
      if(!res.data.phone) {
        this.dialogVisible2 = true;
        this.$message({
          message: '请绑定手机号后操作',
          type: 'warning'
        })
        this.dialogVisible2 = true;
      }
    })
  }
  private authBookClickHandle() {
    this.dialogVisible = true;
  }
  private closeDiaHandle() {
    this.dialogVisible2 = false;
  }

  private findMusic() {
    apiGetMusic({
      id: this.$route.query.id
    }).then((res: any)=> {
      console.log(res.data)
      this.form = {...res.data};
      this.form.style = res.data.style === '' ? [] : res.data.style.split(',');
      this.tempCoverImg = res.data.cover;
      if(res.data.imgs !== '') {
        this.contentImgArr = res.data.imgs.split(';').map((item: any)=> {
          return {
            name:'img1.jpg',
            url:item
          }
        })
      }
      this.form.imgs = (this.form.imgs as any).split(';');
      if(this.contentImgArr.length >= 3){
        this.hideUploadEdit = true;
      }
      
      this.musicFileObj.url = res.data.source_url;
      this.musicFileObj.name = 'music'
      this.musicFileObj.size = ''
      isUpload = true;
    })
  }

  private getCutResult(file: any) {
    if(uploadType === 0) {
      apiUploadCover(file).then((res)=> {
        this.cutDialog = false;
        this.form.cover = res.data.cover;
        this.tempCoverImg = res.data.cover;
        this.form.share_avatar = res.data.share_avatar;
      })
    }else{
      apiUploadImg(file).then((res)=> {
        this.cutDialog = false;
        (this.contentImgArr as any).push({
          url: res.data.filename,
          name: 'img.png'
        })
        if(this.contentImgArr.length >= 3) {
          this.hideUploadEdit = true;
        };
        (this.form.imgs as any).push(res.data.filename)
      })
    }
  }
}
