
































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiList, apiDelete, uporpublish } from '@/api/music'
import { apiGetUserInfo, apiGetMusician } from '@/api/profile'
import { certInfo } from '@/api/users'

@Component({
  name: 'music-page'
})
export default class extends Vue {
  private list = {
    pageSize: Number(window.localStorage.getItem('pageSize')) || 10,
    currentPage: 1,
    total: 0
  }
  private dialogVisible = false
  private loading = false
  private tableData = [
    {
      ID:'',
      esid:'',
      title:'',
      musician_name:'',
      introduction:'',
      tags:'',
      status:'',
      like_count_fake:'',
      share_count_fake:'',
      comment_num: '',
      like_count: '',
      created_at: ''
    }
  ]

  private isCert = false

  created() {
    this.getData()
    this.getCertInfo()
  }

  @Watch('list.pageSize')
  private onListPageSize(v: any) {
    window.localStorage.setItem('pageSize', v)
  }

  private addHandle(){
    this.$router.push({
      path:'/music/upload'
    })
  }

  private handleSizeChange(val: any) {
    console.log(`每页 ${val} 条`);
    this.list.pageSize = val;
    this.getData();
  }

  private handleCurrentChange(val: number):void {
    this.list.currentPage = val;
    this.getData();
  }
  
  private getData():void {
    this.loading = true;
    apiList({
      page_size: this.list.pageSize,
      offset: this.list.currentPage
    }).then((res)=> {
      this.loading = false;
      this.tableData = res.data.data;
      this.list.total = res.data.total;
    })
  }
  
  private handleEdit(_id: string):void {
    this.$router.push({
      path: '/music/upload',
      query: {
        id: _id
      }
    })
  }

  private handleDelete(id: number):void {
    this.$confirm('此操作会删除该歌曲，是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      apiDelete({
        id
      }).then((res: any)=> {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData();
      })
    })
  }

  private getInfoData():void {
    // apiGetUserInfo().then((res: any)=> {
    //   if(!res.data.phone) {
    //     this.$message({
    //       message: '请绑定手机号号和设置艺名后操作',
    //       type: 'warning'
    //     })
    //     this.$router.push({
    //       path:'/profile/modify'
    //     })
    //   }
    // })
    // apiGetMusician().then((res: any)=> {
    //   if(!res.data.name) {
    //     this.$message({
    //       message: '请设置艺名后操作',
    //       type: 'warning'
    //     })
    //     this.$router.push({
    //       path:'/profile/modify'
    //     })
    //   }
    // })
  }

  private computedState(status: number) {
    let map = {
      '0': '正常',
      '1': '已删除',
      '2': '未通过'
    }
    return (map as any)[String(status)];
  }

  private dataTrans(date: string) {
    return date.replace('T', ' ').replace('+08:00', '');
  }

  // private chainClickHandle() {
  //   this.$alert('<p>捧音音乐人，想要得到版权存证服务，请联系捧音工作人员。<br/>微信：pengyinzhushou <br/> 邮箱：help@musenetwork.org</p>', '版权存证', {
  //     dangerouslyUseHTMLString: true
  //   });
  // }

  private chainClickHandle(id: string) {
    this.$router.push({
      path: '/profile/chain',
      query: {
        wid: id
      }
    })
    // this.$alert('<p>捧音音乐人，想要得到版权存证服务，请联系捧音工作人员。<br/>微信：pengyinzhushou <br/> 邮箱：help@musenetwork.org</p>', '版权存证', {
    //   dangerouslyUseHTMLString: true
    // });
  }

  private browerClickHandle(url:string) {
    window.open(url)
    // this.dialogVisible = true
  }
  private publishClickHandle(_id:number) {
    // 验证身份证
    this.$confirm('此操作会将该歌曲发布到捧音“发现”及“曲库”列表中，是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      if(!this.isCert) {
        this.$message({
          message: '需要实名认证',
          type: 'warning'
        });
        this.$router.push({
          path: '/profile/realname'
        })
      } else {
        // 上架操作
        uporpublish({
          wid: _id,
          ishot: 1
        }).then((res:any)=> {
          this.$message({
            message: '上架成功',
            type: 'success'
          });
          (this.tableData.filter((i:any)=>{return i.id === _id})[0] as any).is_hot = 1
        })
      }
    })
  }
  private downClickHandle(_id:number) {
    this.$confirm('此操作会下架该歌曲，是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      // 下架操作
      uporpublish({
        wid: _id,
        ishot: 0
      }).then((res:any)=> {
        this.$message({
          message: '下架成功',
          type: 'success'
        });
        // console.log(this.tableData.filter((i:any)=>{return i.id === _id}))
        // console.log(this.tableData)
        (this.tableData.filter((i:any)=>{return i.id === _id})[0] as any).is_hot = 0
      })
    })
  }

  private getCertInfo() {
    certInfo().then((res: any)=> {
      this.isCert = res.data.face_verify === 'T'
    })
  }
}
