




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueCropper }  from 'vue-cropper' 
import { getDuration, getImgSize, file2Base64, dataURLtoBlob, blobToUint8Array } from '@/utils/index'
@Component({
  name: 'cut',
  components: {
    VueCropper
  }
})
export default class extends Vue {
  @Prop({ default: "" }) private blob!: string
  @Prop({ default: false }) private cutDialog!: boolean
  @Prop({ default: ()=>{} }) private getResult!: any

  private created() {
  }
  @Watch('cutDialog')

  private onCreatedChange(v: any) {
    if(v) {
      this.loading = false;
    }
  }

  private oncutDialogChange(v: any) {
  }

  private loading = false;

  private option = {
    img: '', // 裁剪图片的地址
    info: false, // 裁剪框的大小信息
    outputSize: 1, // 剪切后的图片质量（0.1-1）
    full: true, // 输出原图比例截图
    outputType: 'png', // 裁剪生成额图片的格式
    canMove: false,  // 能否拖动图片
    original: false,  // 上传图片是否显示原始宽高
    canMoveBox: true,  // 能否拖动截图框
    autoCrop: true, // 是否默认生成截图框
    fixedBox: false, // 截图框固定大小
    autoCropWidth: 300, // 默认生成截图框宽度	
    autoCropHeight: 300,  // 默认生成截图框高度
    // mode: '100%', // 图片默认渲染方式
    fixedNumber: [1,1], // 截图框的宽高比例	
    centerBox: true, // 截图框是否被限制在图片里面	
    infoTrue: true, // 裁剪框的大小信息	
    fixed: true // 是否固定比例
  }
  private mounted() {  
    
  }
  private cutSubmit() {
    this.loading = true;
    (this.$refs.cropper as any).getCropBlob((data: any) => {
      // this.loading = false
      let formData = new FormData();
      var fileOfBlob = new File([data], 'img.jpg', {type:"image/jpg"});
      formData.append("file", fileOfBlob);
      this.$emit('getResult', formData)
    })
  }
}
